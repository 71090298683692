<template>
    <div class="header_fix" :class="is_login ? 'is_login' : ''">
        <div v-if="JSON.stringify(userinfo) !== '{}'" class="login_box">
            <div class="m1440 flex-box" style="justify-content:space-between;">
                <div class="flex-box flex-end points ">
                    <div class="head_point flex-box">
                        M-Coins <span>{{ userinfo.coins | numberToCurrency }}</span>
                    </div>
                    <div class="head_point flex-box">
                        RP <span>{{ userinfo.rp | numberToCurrency }}</span>
                    </div>
                    <div class="head_point flex-box">
                        PP <span>{{ userinfo.pp | numberToCurrency }}</span>
                    </div>
                    <div class="head_point flex-box">
                        AP <span>{{ userinfo.ap | numberToCurrency }}</span>
                    </div>
                    <div class="head_point flex-box">
                        EP <span>{{ userinfo.ep | numberToCurrency }}</span>
                    </div>
                </div>
                <div class="flex-box m-arrow-down" @click="showMore">
                    <div class="m-cell-rt" id="rt">
                        <router-link to="" class="flex-box">
                            <!-- <img src="../assets/static/icon/pic3.png" class="avatar" alt="" /> -->
                            <img :src='userinfo.avatar ? userinfo.avatar : require("../assets/static/icon/avatar.png")' class="avatar" alt="" id="avatar">
                            <div class="col5 fs14 mr5 flex-box" id="vip" :class="userinfo.is_special == 1 ? 'vip' : ''">{{ userinfo.idnum }}【{{userinfo.id}}】</div>
                        </router-link>
                        <div class="more_account" v-if="accountPop" id="menu">
                            <div class="account_item" v-for="item in options" @click="currStationChange(item)">{{ item }}</div>
                        </div>
                    </div>

                    <router-link to="/message" class="msg" :class="userinfo.msg > 0 ? 'noread' : ''"></router-link>
                </div>
            </div>
        </div>
        <div class="header flex-box m1440">

            <img src="../assets/static/icon/logo2.png" class="logo" alt="" />
            <!-- <div class="sign sign_in" @click="toggleLang">{{ $i18n.locale === 'zh' ? 'English' : '中文' }}</div> -->

            <div class="flex-box links">
                <div class="flex-box">

                    <router-link to="/" class="nav" :class="headerIndex == 0 ? 'active' : ''"> {{ $t('home') }}</router-link>
                    <router-link to="/market" class="nav" :class="headerIndex == 1 ? 'active' : ''">{{ $t('market') }}</router-link>
                    <router-link to="/map" class="nav" :class="headerIndex == 2 ? 'active' : ''">{{ $t('map') }}</router-link>
                    <router-link to="/card" class="nav" :class="headerIndex == 3 ? 'active' : ''">{{ $t('card') }}</router-link>
                    <router-link to="/basic_info" class="nav" :class="headerIndex == 4 ? 'active' : ''">{{ $t('personal') }}</router-link>
                    <div class="nav change" @click="toggleLang">{{ $t('change') }}</div>
                </div>
                <div class="signs flex-box flex-between">
                    <div class="sign sign_out" v-if="JSON.stringify(userinfo) !== '{}'" @click="signout">{{ $t('signout') }}</div>
                    <template v-else>
                        <div class="sign sign_in" @click="gosignin">{{ $t('login') }}</div>
                        <div class="sign sign_up" @click="gosignup">{{ $t('signup') }}</div>
                    </template>
                </div>
            </div>
            <a href="javascript:;" class="menu" @click="drawer = !drawer"></a>
        </div>
        <div class="drawer" :class="drawer ? 'show' : ''">
            <router-link to="/" class="nav block" :class="headerIndex == 0 ? 'active' : ''">{{ $t('home') }}</router-link>
            <router-link to="/market" class="nav block" :class="headerIndex == 1 ? 'active' : ''">{{ $t('market') }}</router-link>
            <router-link to="/map" class="nav block" :class="headerIndex == 2 ? 'active' : ''">{{ $t('map') }}</router-link>
            <router-link to="/card" class="nav block" :class="headerIndex == 3 ? 'active' : ''">{{ $t('card') }}</router-link>
            <router-link to="/basic_info" class="nav block" :class="headerIndex == 4 ? 'active' : ''">{{ $t('personal') }}</router-link>
            <router-link to="/basic_info" class="nav_item fs16" :class="nextLevel == 0 ? 'active' : ''">Basic Information</router-link>
            <router-link to="/coins_account" class="nav_item fs16" :class="nextLevel == 1 ? 'active' : ''">M-Coins Account</router-link>
            <router-link to="/rp_account" class="nav_item fs16" :class="nextLevel == 2 ? 'active' : ''">RP Account</router-link>
            <router-link to="/pp_account" class="nav_item fs16" :class="nextLevel == 3 ? 'active' : ''">PP Account</router-link>
            <router-link to="/ap_account" class="nav_item fs16" :class="nextLevel == 4 ? 'active' : ''">AP Account</router-link>
            <router-link to="/ep_account" class="nav_item fs16" :class="nextLevel == 5 ? 'active' : ''">EP Account</router-link>
            <router-link to="/member_register" class="nav_item fs16" :class="nextLevel == 6 ? 'active' : ''">Member Registration</router-link>
            <router-link to="/message" class="nav_item fs16" :class="nextLevel == 7 ? 'active' : ''">Message</router-link>
            <div class="signs flex-box flex-between">
                <div class="sign sign_out" v-if="JSON.stringify(userinfo) !== '{}'" @click="signout">Sign Out</div>
                <template v-else>
                    <!-- <div class="sign sign_in" @click="gosignin">Sign In</div> -->
                    <router-link to="/login?tabindex=1" class="sign sign_in">Login</router-link>

                    <!-- <div class="sign sign_up" @click="gosignup">Sign Up</div> -->
                    <router-link to="/login?tabindex=0" class="sign sign_up">Sign Up</router-link>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { numberToCurrency } from "../assets/filters";
import cookie from "vue-cookies";
import { RouterLink } from "vue-router";
import axios from "axios";

export default {
    name: "Header",
    filters: {
        numberToCurrency
    },
    props: {
        "headerIndex": {
            type: Number,
            default: -1
        },
        "login": {
            type: Boolean,
            default: true,
        },
        "nextLevel": {
            type: Number,
            default: -1
        },
    },
    data() {
        return {
            drawer: false,
            is_login: false,
            userinfo: {},
            accountPop: false,
            options: []

        };
    },
    mounted() {
        var token = cookie.get("sma_token");
        console.log(token);
        if (!token) {
            this.is_login = false;
        }
        else {
            this.is_login = true;
            this.$api.apiUserInfo({}).then(ret => {
                if (ret.code == 1) {
                    this.userinfo = ret.data.userinfo;
                    this.options = this.userinfo.alluser

                    cookie.set("sma_userinfo", ret.data.userinfo);
                }
            });
        }
        console.log(this.userinfo);
        //点击任意位置关闭区域弹窗
        document.addEventListener('click', (e) => {
            console.log(e.target)
            //获取弹窗对象
            const userCon = document.getElementById('menu');

            const rt = document.getElementById('rt');
            const vip = document.getElementById('vip');
            const avatar = document.getElementById('avatar');
            if (vip.contains(e.target) || avatar.contains(e.target)|| rt.contains(e.target)) {
                return false;
            }
            //判断弹窗对象中是否包含点击对象
            if (userCon && !userCon.contains(e.target)) {
                //如果包含则跳转回之前的页面
                this.accountPop = false;
            }
        })
    },
    methods: {
        currStationChange(e) {
            console.log(e)
            //切换用户信息

            this.$api.apiChangeLogin({ user_id: e }).then(ret => {
                if (ret.code == 1) {
                    cookie.set("sma_token", ret.data.userinfo.token);
                    cookie.set("sma_userinfo", ret.data.userinfo);
                    axios.defaults.headers['token'] = ret.data.userinfo.token;
                    // axios.defaults.headers.post["token"] =
                    if (this.$route.path === '/') {
                        window.location.reload(true);
                    }else{
                        this.$router.push({ path: '/' })
                    }                    
                }
            })

        },
        showMore() {
            this.accountPop = !this.accountPop;
        },
        toggleLang() {
            if (this.$i18n.locale === 'zh') {
                this.$i18n.locale = 'en'
                cookie.set("sma_language", 'en');
            } else {
                this.$i18n.locale = 'zh'
                cookie.set("sma_language", 'zh');

            }
        },
        gosignin() {
            this.$router.push({ path: '/login', query: { tabindex: 1 } });
        },
        gosignup() {
            this.$router.push({ path: '/login', query: { tabindex: 0 } });
        },
        signout() {
            var token = cookie.get("sma_token");
            if (!token) {
                Message({
                    message: '已经登出，请勿重复',
                    type: 'error',
                    offset: window.screen.height / 2
                })
                // Message.error('已经登出，请勿重复');
                if (this.$route.path === '/') {
                    window.location.reload(true);
                }else{
                    this.$router.push({ path: '/' })
                }       
            }
            let that = this;
            MessageBox.confirm("确定要退出嘛?", "提示", { type: "warning" })
                .then((res) => {
                    // 判断 是否是 确认
                    console.log(res);
                    if (res == "confirm") {
                        console.log(888);
                        this.$loading({
                            lock: true, // 是否禁止背景滚动，默认为false
                            text: 'Loading', // 加载文本提示，默认为'Loading'
                            spinner: 'el-icon-loading', // 加载图标类型，默认为'el-icon-loading'
                            background: 'rgba(0, 0, 0, 0.7)' // 遮罩层背景颜色，默认为'rgba(0, 0, 0, 0.7)'
                        })
                        // this.$api.apiPostLogin({account:this.account,pwd:this.pwd}).then(ret=>{
                        that.$api.apiLogOut({}).then((res) => {
                            // 清除token
                            localStorage.removeItem("sma_token");
                            cookie.remove("sma_token");
                            cookie.remove("sma_userinfo");
                            window.location.reload(true);
                            // window.reload();
                            // that.$router.go(0);
                            // that.$router.push({ path: '/' })
                        });
                    }
                })
                .catch((err) => {
                    //    console.log(err);
                });
        }
    },
    components: { RouterLink }
};
</script>