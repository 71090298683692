import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import userInfo from './module/userInfo'
export default new Vuex.Store({
	state: {
		//state是存放数据的仓库
	},
	mutations: {
		//mutations是更改仓库中的数据
	},
	actions: {
		//actions是异步操作获取数据
	},
	getters: {
		//getters是加工仓库中的数据
	},
  modules:{
    userInfo,
  }
});
