import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const routes = [
	// 注册
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/login.vue"),
	},
	// 首页
	{
		path: "/",
		name: "Index",
		component: () => import("../views/index.vue"),
	},
	// 文章详情
	{
		path: "/articledetail",
		name: "articledetail",
		component: () => import("../views/articledetail.vue"),
	},
	// market
	{
		path: "/market",
		name: "Market",
		component: () => import("../views/market.vue"),
	},
	// card
	{
		path: "/card",
		name: "Card",
		component: () => import("../views/card.vue"),
	},
	// map
	{
		path: "/map",
		name: "Map",
		component: () => import("../views/map.vue"),
	},
	// personal
	{
		path: "/basic_info",
		name: "Basic_info",
		component: () => import("../views/basic_info.vue"),
	},
	{
		path: "/coins_account",
		name: "Coins_account",
		component: () => import("../views/coins_account.vue"),
	},
	{
		path: "/rp_account",
		name: "Rp_account",
		component: () => import("../views/rp_account.vue"),
	},
	{
		path: "/transaction_description",
		name: "Transaction_description",
		component: () => import("../views/transaction_description.vue"),
	},
	{
		path: "/pp_account",
		name: "Pp_account",
		component: () => import("../views/pp_account.vue"),
	},
	{
		path: "/ap_account",
		name: "Ap_account",
		component: () => import("../views/ap_account.vue"),
	},
	{
		path: "/ep_account",
		name: "Ep_account",
		component: () => import("../views/ep_account.vue"),
	},
	{
		path: "/member_register",
		name: "Member_register",
		component: () => import("../views/member_register.vue"),
	},
	{
		path: "/member_register2",
		name: "Member_register2",
		component: () => import("../views/member_register2.vue"),
	},
	{
		path: "/history",
		name: "History",
		component: () => import("../views/history.vue"),
	},
	{
		path: "/message",
		name: "Message",
		component: () => import("../views/message.vue"),
	},
	{
		path: "/terms",
		name: "Terms",
		component: () => import("../views/terms.vue"),
	},
	{
		path: "/my_under",
		name: "My_under",
		component: () => import("../views/my_under.vue"),
	},
	{
		path: "/exstingfrofile",
		name: "Exstingfrofile",
		component: () => import("../views/exstingfrofile.vue"),
	},
	{
		path: "/changepassword",
		name: "Changepassword",
		component: () => import("../views/changepassword.vue"),
	},
	{
		path: "/invitecode",
		name: "Invitecode",
		component: () => import("../views/invitecode.vue"),
	},
	{
		path: "/changeemail",
		name: "Changeemail",
		component: () => import("../views/changeemail.vue"),
	}
];
const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
