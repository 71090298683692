// lang.js
export default {
    zh: {
        home: '首页',
        market:'市场',
        map:'系谱',
        card:'制卡',
        personal:'个人中心',
        basicinformation:'基础信息',
        changepassword:'修改密码',
        mcoins:'M-Coins账户',
        rp:'RP账户',
        pp:'PP账户',
        ap:'AP账户',
        ep:'EP账户',
        helpregistration:'代注册',
        message:'消息',
        recommendation:'推荐',
        change:'English',
        login:'登 录',
        signin:'登 录',
        signup:'注 册',
        signout:'退 出',
        profile:'户口管理',
        member:'会员管理',
        account:'账户管理',
        infocenter:'信息中心',
        name:'姓名',
        birth:'出生年月日',
        gender:'性别',
        phone:'手机号',
        realname:'真实姓名',
        banknumber:'开户卡号',
        bankname:'开户行',
        make:'制卡',
        history:'历史',
        cardamount:'制卡金额',
        cardamountinput:'请输入制卡金额',
        transactionpwdinput:'请输入交易密码',
        cardnumberinput:'请输入制卡数量',
        transactionpwd:'交易密码',
        cardnumber:' 交易数量',
        oldpassword:'旧密码',
        newpassword:'新密码',
        confirmpassword:'确认密码',
        confirm:'提交',
        transactionpasswordsettings:'交易密码设置',
        oldtranscation:'旧交易密码',
        newtranscation:'新交易密码',
        confirmtranscation:'确认交易密码',
        select:' 选择',
        exsitingprofile:'关联账户',
        newprofile:'新账户',
        fillinformation:'填写信息',
        email:'邮箱',
        accountnumber:'账号',
        password:'密码',
        contactperson:'接入点',
        selectmarket:'左右区',
        invitationcode:'邀请码',
        myrecommendation:'我的推荐',
        date:'时间',
        player_id:'用户id',
        price:'金额',
        accessid:'接入点',
        number:'数量',
        type:'币种',
        records:'记录',
        from:'来源',
        types:'类型',
        cardnotice:'1.邀请人可以输入制卡金额（金额将从邀请人的EP账户中扣除），普通会员可以制作200-5000级别的会员卡；只有VIP会员才能生成5000美金的VIP会员卡；<br/>2.新注册人输入邀请人编号，注册后与邀请人绑定层级关系，将制卡生成的卡金额按比例分配给新注册人的RP、PP、AP、EP账户；',
        playerprofile:'账户类型',
        signupcode:'注册码',
        screenname:'账号',
        haveaccont:'已有帐号?',
        invitecode:'邀请码',
        copy:'复制',
        cardnum:'卡号',
        changeemail:'修改邮箱',
        send:'发送验证码',
        registrationtime:'注册时间',
        headsculpture:'头像',
        transcation:'交易密码',
        sure:'确认修改',
        newemail:'新邮箱',
        code:'验证码',
        left:'左区',
        right:'右区',
        submit:'确认',
        inviteId:'推荐人编号'

    },
    en: {
        home: 'Home',
        market:'Market',
        map:'Map',
        card:'Card',
        personal:'Personal',
        basicinformation:'Basic Information',
        changepassword:'Change Password',
        mcoins:'M-Coins Account',
        rp:'RP Account',
        pp:'PP Account',
        ap:'AP Account',
        ep:'EP Account',
        helpregistration:'Member Registration',
        message:'Message',
        recommendation:'My recommendation',
        change:'中文',
        login:'Login',
        signin:'Sign In',
        signup:'Sign Up',
        signout:'Sign Out',
        profile:'Profile',
        member:'Member',
        account:'Account',
        infocenter:'Info Center',
        name:'Name',
        birth:'Date Of Birth',
        gender:'Gender',
        phone:'Phone Number',
        realname:'Realname',
        banknumber:'Bank Card Number',
        bankname:'Bankname',
        
        make:'Make',
        history:'History',
        cardamount:'CARD MAKING AMOUNT',
        cardamountinput:'Please enter the card printing amount',
        transactionpwdinput:'Please enter transaction password',
        cardnumberinput:'Please enter number of cards produced',
        transactionpwd:'Transaction Password',
        cardnumber:' Number of Cards Produced',
        oldpassword:'Old Password',
        newpassword:'New Password',
        confirmpassword:'Confirm Password',
        confirm:'Confirm',
        transactionpasswordsettings:'Transaction Password Settings',
        oldtranscation:'Old Transaction Password',
        newtranscation:'New Transaction Password',
        confirmtranscation:'Confirm Transaction Password',
        select:'Select',
        exsitingprofile:'EXISTING PROFILE',
        newprofile:'NEW PROFILE',
        
        fillinformation:'FILL IN INFORMATION',//填写信息
        email:'Email',
        accountnumber:'Account Number',
        password:'Password',
        contactperson:'Contact Person Number',
        selectmarket:'Selecting The Market',
        invitationcode:'Invitation Code',
        myrecommendation:'My recommendation',
        date:'Date',
        player_id:'Player ID',
        price:'Price',
        accessid:'Access ID',
        number:'Number',
        type:'Type',
        records:'RECORDS',
        from:'From',
        types:'Type',
        cardnotice:'1. The inviter can input the card making amount (the amount will be deducted from the inviter\'s EP account), and ordinary members can make membership cards at the level of 200-5000; Only VIP members can generate a VIP membership card worth $5000;<br\>2. The new registrant inputs the inviter number, binds the hierarchical relationship with the inviter after registration, and allocates the card amount generated by card making proportionally to the new registrant\'s RP, PP, AP, and EP accounts;',
        playerprofile:'Player ID Profile',
        signupcode:'Sign Up Code',
        screenname:'Screen Name',
        haveaccont:'Already have an account?',
        invitecode:'Invitecode',
        copy:'Copy',
        cardnum:'Card Number',
        changeemail:'Change Email',
        send:'Send verification code',
        registrationtime:'Registration Time',
        headsculpture:'Head Sculpture',
        transcation:'Transcation',
        sure:'Sure',
        newemail:'New Email',
        code:'Code',
        left:'Left',
        right:'Right',
        submit:'Sure',
        inviteId:'Invite Id'

    }
  }