<template>
    <div class="left_scroll">
        <div class="info_box">
            <img :src="userinfo.avatar ? userinfo.avatar : require('../assets/static/icon/avatar.png')" class="avatar"
                alt="">
            <div class="fs20 fwb tc pb10">{{ userinfo.idnum }}</div>
            <!-- <div class="fs15 fwb tc pb10">ID:{{userinfo.id}}</div> -->
            <div class="flex-box flex-center">
                <el-select v-model="userinfo.id" class="select" @change="currStationChange">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item"></el-option>
                </el-select>
            </div>
            <div class="flex-box flex-center">
                <div class="vip">{{ userinfo.levelmsg.price }}</div>
            </div>
        </div>
        <div class="left_nav">
            <!--<div class="fs20 pl20 fwb pb25">{{ $t('personal') }}</div>-->
            <div class="nav_item_one">
                <div class="one_title">{{ $t('profile') }}</div>
                <router-link to="/basic_info" class="nav_item fs16"
                    :class="leftIndex == 0 ? 'active' : ''">{{ $t('basicinformation') }}</router-link>
                <router-link to="/changepassword" class="nav_item fs16"
                    :class="leftIndex == 9 ? 'active' : ''">{{ $t('changepassword') }}</router-link>
                <router-link to="/changeemail" class="nav_item fs16"
                    :class="leftIndex == 11 ? 'active' : ''">{{ $t('changeemail') }}</router-link>
            </div>

            <div class="nav_item_one">
                <div class="one_title">{{ $t('member') }}</div>
                <router-link to="/member_register" class="nav_item fs16"
                :class="leftIndex == 6 ? 'active' : ''">{{ $t('helpregistration') }}</router-link>
                <router-link to="/my_under" class="nav_item fs16"
                :class="leftIndex == 8 ? 'active' : ''">{{ $t('recommendation') }}</router-link>
                <router-link to="/invitecode" class="nav_item fs16"
                :class="leftIndex == 10 ? 'active' : ''">{{ $t('invitecode') }}</router-link>
            </div>

            <div class="nav_item_one">
                <div class="one_title">{{ $t('account') }}</div>
                <router-link to="/coins_account" class="nav_item fs16"
                :class="leftIndex == 1 ? 'active' : ''">{{ $t('mcoins') }}</router-link>
            <router-link to="/rp_account" class="nav_item fs16"
                :class="leftIndex == 2 ? 'active' : ''">{{ $t('rp') }}</router-link>
            <router-link to="/pp_account" class="nav_item fs16"
                :class="leftIndex == 3 ? 'active' : ''">{{ $t('pp') }}</router-link>
            <router-link to="/ap_account" class="nav_item fs16"
                :class="leftIndex == 4 ? 'active' : ''">{{ $t('ap') }}</router-link>
            <router-link to="/ep_account" class="nav_item fs16"
                :class="leftIndex == 5 ? 'active' : ''">{{ $t('ep') }}</router-link>
            </div>

            <div class="nav_item_one">
                <div class="one_title">{{ $t('infocenter') }}</div>
                <router-link to="/message" class="nav_item fs16"
                :class="leftIndex == 7 ? 'active' : ''">{{ $t('message') }}</router-link>
            </div>
           
            <!-- <router-link to="/exstingfrofile" class="nav_item fs16" :class="leftIndex == 9 ? 'active' : ''">EXISTING PROFILE</router-link> -->
           
           
        </div>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";

export default {
    name: "left_nav",
    props: {
        "leftIndex": {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            userinfo: {},
            options: [],
        };
    },
    created() {

        if (!cookie.get('sma_token')) {
            this.$router.push({ path: '/login', query: { tabindex: 1 } })
            return
        }
        this.userinfo = cookie.get('sma_userinfo')
        this.options = this.userinfo.alluser

    },
    methods: {
        changeLeft(index) {
            this.leftIndex = index;
            this.$emit('getLeftIndex', index)
        },
        currStationChange(e) {
            console.log(e)
            //切换用户信息

            this.$api.apiChangeLogin({ user_id: e }).then(ret => {
                if (ret.code == 1) {
                    cookie.set("sma_token", ret.data.userinfo.token);
                    cookie.set("sma_userinfo", ret.data.userinfo);
                    axios.defaults.headers['token'] = ret.data.userinfo.token;
                    // axios.defaults.headers.post["token"] =

                    this.$router.push({ path: '/' })
                }
            })

        }
    },
};
</script>
<style lang="less" scoped>
.left_scroll {
    // height: calc(100vh - 190px);overflow-y: scroll;
}

.left_scroll::-webkit-scrollbar {
    display: none;
}

.info_box {
    width: 300px;
    background: #FFFFFF;
    border-radius: 2px;
    padding: 40px 0;
    margin-bottom: 20px;

    .avatar {
        width: 106px;
        height: 106px;
        border-radius: 50%;
        display: block;
        margin: 0 auto 14px;
        object-fit: cover;
    }

    .vip {
        height: 16px;
        background: #FFE09E;
        border-radius: 8px;
        font-size: 10px;
        color: #333;
        padding-left: 15px;
        display: inline-block;
        padding-right: 5px;
        position: relative;
        margin: 0 auto;

        &::before {
            content: "";
            display: block;
            width: 22px;
            height: 17px;
            background: url('../assets/static/icon/vip2.png') no-repeat center;
            background-size: 100%;
            position: absolute;
            left: -8px;
            top: 0;
            bottom: 0;
        }
    }

}

.left_nav {
    width: 300px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    padding: 44px 36px 30px;

    .one_title {
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        font-size: 16px;
    }

    .nav_item {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 20px;
        position: relative;
        font-size: 14px;
        color: #555;
        margin-bottom: 8px;

        &:hover,
        &.active {
            background: rgba(226, 2, 0, 0.05);
            border-right: 3px solid var(--red);
            color: #101010;
            font-weight: bold;
        }

    }
}</style>