<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import cookie from "vue-cookies";

export default {
  
    created(){
      // if(!cookie.get('sma_language')){
      //   cookie.set("sma_language",1);

      // }
      if(!cookie.get('sma_language')){
            cookie.set("sma_language",'en');
      }
      let that = this;
      var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
      var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
      if (isWeixin) {
        // MessageBox.confirm("请在其他浏览器中打开?", "提示", { type: "success" })
        this.$router.replace({path:'/exstingfrofile'})

        return false;
      }
    },
  }
    window.onload = function() {
      
    // 禁用双指放大
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    });
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, {
        passive: false
    });
   
  };
</script>