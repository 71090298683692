export default {
	state: {
		//state是存放数据的仓库
		userInfo: {
			coins: 1100,
			rp: 0,
			pp: 0,
			ap: 0,
			ep: 0,
			msg: 1,
			avatar: "../../assets/static/icon/avatar.png",
			name: "shenhua",
			vip: true,
		},
	},
	mutations: {
		//mutations是更改仓库中的数据
	},
	actions: {
		//actions是异步操作获取数据
	},
	getters: {
		//getters是加工仓库中的数据
	},
};
