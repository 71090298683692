import {
    get,
    post,
} from './http.js';
export const apiPostLogin = p => post('user/login', p); //登录
export const apiPostSend = p => post('v1/sms/ali/send', p); //发送短信
export const apiRegister  = p => post('user/register', p); //注册
export const apiPostPassword = (p,h) => {return post('user/changeTpwd', p,h);} //修改交易密码
export const changepassword = (p,h) => {return post('user/resetpwd', p,h);} //修改登录密码
export const apiSendcode = (p,h) => {return post('ems/send', p,h);} //发送验证码
export const changeemail = (p,h) => {return post('user/changeemail', p,h);} //发送验证码

export const apiForgetPassword = (p,h) => {return post('ems/send_email', p,h);} //忘记密码
export const apiLogOut = p =>  post('user/logout', p) //退出
// export  const userISLogin = p =>post('tool/isLogin',p)//试用网站登录
export const config = p => post('common/config', p) //网站配置
export const apiArticle = p => post('article/list', p); //首页文章
export const getbanner = p => post('index/banner', p); //首页轮播
export const apiChangeLogin = p => post('user/changelogin', p); //切换登录

export const apiArticleDetail = p => post('article/detail', p); //文章详情

export const apiUpload = p => post('v1/cdn/ali/upload', p); //上传头像
export const apiUserInfo = p => post('user/index', p); //获取用户信息
export const apiGetIndex = p => post('index/index/index', p); //首页商品列表
export const apiGoodsDetail = p => post('v1/product/queryVO', p); //商品详情
export const apiLanguage = p => post('v1/language/queryContentVO', p); //变量说明

export const apiEploglist = p => post('user/eplog', p); //ep账户交易
export const apiEpexchange = p => post('user/epexchange', p); //ep账户交易

export const apiAploglist = p => post('user/aplog', p); //ap账户
export const apiRploglist = p => post('user/rplog', p); //rp账户
export const apiPploglist = p => post('user/pplog', p); //pp账户
export const apiCouinsloglist = p => post('user/coinslog', p); //代币账户

export const apiDocard = p => post('user/docard', p); //制卡
export const apiUpdateDocard = p => post('user/updatedocard', p); //制卡
export const apiSearchcardd = p => post('user/searchcard', p); //制卡



export const apiDocardlog = p => post('user/docardlog', p); //制卡
export const apiGrouplist = p => post('user/grouplist', p); //发展关系
export const apiHelpregister = p => post('user/helpregister', p); //代注册

export const apiMessagelist = p => post('user/messagelist', p); //消息列表

export const editUserInfo = p => post('user/update', p)// 编辑个人信息
export const apiRpchange = p => post('coins/rpchange', p); //兑换代币
export const apiCoinssell = p => post('coins/coinssell', p); //代币售卖
export const apiCoinsbuy = p => post('coins/coinsbuy', p); //代币购买

export const apiSelllist = p => post('coins/selllist', p); //代币售卖
export const apiBuylist = p => post('coins/buylist', p); //代币售卖
export const apiTotallist = p => post('coins/totallist', p); //代币售卖
export const apiinveitelist = p => post('user/inveitelist', p); //代币售卖

    




