import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import '/src/assets/css/reset.css'
import '/src/assets/css/style.css'
import Header from '/src/components/header.vue'
import Footer from '/src/components/footer.vue'
import Popup from '/src/components/popup.vue'
import LeftNav from '/src/components/leftnav.vue'
import * as api from './request/api.js';// 导入api接口
import ElementUI from 'element-ui';
import store from './store/index'
import 'echarts'
import ECharts from 'vue-echarts'
import cookie from "vue-cookies";
import VueI18n from 'vue-i18n'
import lang from './lang'
// import './utils/flexible'
Vue.component('v-chart', ECharts)
// 全局变量引入global
// import global from "@/global/global.js"
// Vue.prototype.global = global
if(!cookie.get('sma_language')){
  cookie.set("sma_language",'en');
}
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: cookie.get('sma_language'), // 默认语言为中文
  messages: lang
})


Vue.config.productionTip = false
Vue.prototype.$api = api;//挂载
new Vue({
  router,
  render: h => h(App),
  store,
  i18n,
}).$mount('#app')
// Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.component('Header',Header)
Vue.component('Footer',Footer)
Vue.component('Popup',Popup)
Vue.component('LeftNav',LeftNav)
//弹出框禁止滑动
Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}